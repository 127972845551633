import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import classNames from 'classnames';

import { onPageNavigationEvent } from 'src/lib/analytics';

import styles from './Dynamic.module.scss';

export default function DynamicSectionHeader({
  href,
  as,
  category,
  priority,
  clickLocation,
  clickTitle,
  children,
}) {
  const Tag = `h${priority}`;
  if (href) {
    return (
      <Link href={href} as={as}>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <a
          className={styles['header--link']}
          onClick={
            clickLocation && clickTitle
              ? () => onPageNavigationEvent(clickLocation, 'link', clickTitle)
              : undefined
          }
          onKeyUp={
            clickLocation && clickTitle
              ? () => onPageNavigationEvent(clickLocation, 'link', clickTitle)
              : undefined
          }
        >
          <Tag
            className={classNames(
              styles.header,
              category && styles[`header--${category}`]
            )}
          >
            {children}
          </Tag>
        </a>
      </Link>
    );
  }
  return (
    <Tag
      className={classNames(
        styles.header,
        category && styles[`header--${category}`]
      )}
    >
      {children}
    </Tag>
  );
}

DynamicSectionHeader.propTypes = {
  children: PropTypes.node.isRequired,
  priority: PropTypes.number.isRequired,
  href: PropTypes.string,
  as: PropTypes.string,
  category: PropTypes.string,
  clickLocation: PropTypes.string,
  clickTitle: PropTypes.string,
};

DynamicSectionHeader.defaultProps = {
  href: null,
  as: null,
  category: '',
  clickLocation: '',
  clickTitle: '',
};
