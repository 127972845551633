import React from 'react';
import Link from 'next/link';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { onPageNavigationEvent, placementClickEvent } from 'src/lib/analytics';

import Headshot from 'src/components/columnist/Headshot';
import ArticleList from 'src/components/links/article-list/ArticleList';

import styles from './Card.module.scss';

const Card = ({
  headshotSize,
  feature,
  articles,
  displayContext,
  clickLocation,
  clickPosition,
  trackPosition,
  variant,
}) => (
  <div className={styles.card}>
    {/* TODO: Investigate placeholder strategies to optimize lazy loading */}
    <LazyLoadComponent threshold={400} visibleByDefault={variant === 'a-z'}>
      <Headshot
        avatarUrl={feature.featureAvatarUrls.small}
        featureName={feature.featureName}
        featureShortName={feature.featureShortName}
        featureCategory={feature.category}
        creators={feature.creators}
        bylineOverride={feature.bylineOverride}
        size={headshotSize}
        clickLocation={clickLocation}
        bylineSpacing={displayContext !== 'highlights'}
        onCard
      />
    </LazyLoadComponent>
    {displayContext === 'category' ? (
      <Link href="/[category]" as={`/${feature.category}`}>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <a
          className={classNames(
            styles.card__link,
            `${styles[`card__link_${feature.category}`]}`
          )}
          alt={`${feature.category} category`}
          onClick={() => {
            onPageNavigationEvent(clickLocation, 'link', feature.category);
            placementClickEvent(
              clickLocation,
              clickPosition,
              feature.category,
              feature.featureName
            );
          }}
          onKeyUp={() => {
            onPageNavigationEvent(clickLocation, 'link', feature.category);
            placementClickEvent(
              clickLocation,
              clickPosition,
              feature.category,
              feature.featureName
            );
          }}
        >
          {feature.category}
        </a>
      </Link>
    ) : (
      <ArticleList
        featureName={feature.featureName}
        featureShortName={feature.featureShortName}
        featureCategory={feature.category}
        articles={articles}
        clickLocation={clickLocation}
        clickPosition={clickPosition}
        trackParentPosition={trackPosition}
      />
    )}
  </div>
);

Card.propTypes = {
  headshotSize: PropTypes.oneOf(['sm', 'md', 'lg']).isRequired,
  feature: PropTypes.shape({
    featureName: PropTypes.string.isRequired,
    featureShortName: PropTypes.string.isRequired,
    featureAvatarUrls: PropTypes.shape({
      small: PropTypes.string.isRequired,
    }).isRequired,
    creators: PropTypes.arrayOf(
      PropTypes.shape({
        fullName: PropTypes.string.isRequired,
      }).isRequired
    ).isRequired,
    category: PropTypes.string.isRequired,
    bylineOverride: PropTypes.string,
  }).isRequired,
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      publishDate: PropTypes.string.isRequired,
    })
  ),
  displayContext: PropTypes.oneOf(['articles', 'category', 'highlights']),
  clickLocation: PropTypes.string,
  clickPosition: PropTypes.number,
  trackPosition: PropTypes.bool,
  variant: PropTypes.string,
};

Card.defaultProps = {
  articles: null,
  displayContext: 'articles',
  clickLocation: '',
  clickPosition: null,
  trackPosition: false,
  variant: null,
};

export default Card;
